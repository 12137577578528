import React from "react";

import Layout from "../../components/layout";
import {OrderBreadcrumb as Breadcrumb} from "../../components/breadcrumb";
import {Callout} from "../../components/alert";
import {KeyHeader} from "../../components/typography";

const pageTitle = "References";

const Article = ({children}) => (
    <article className="card my-4">{children}</article>
);

const Page = () => (
    <Layout pageTitle={pageTitle}
            pageNav={<Breadcrumb activeName={pageTitle}/>}
    >
        <p>
            If you have a couple of minutes and if you have something to
            say about SQLAPI++ product and support service, please make a short
            summary:
        </p>
        <ul>
            <li>Your country and company name</li>
            <li>Your full name</li>
            <li>
                Anything you would like to tell others about SQLAPI++ and
                its support service
            </li>
        </ul>
        <p>
            and send it to {' '}<a href="mailto:references@sqlapi.com">references@sqlapi.com</a>.
            We appreciate your time!
        </p>
        <Callout heading="Privacy">
            Please, note whether you allow us to publish your email on
            our page or not. If you omit the consent, {' '}<strong>the default is NO</strong>{' '} and only
            your country, company name and full name will be published.
        </Callout>

        <KeyHeader>References</KeyHeader>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:cmiller@nucentrix.net">Charlie Miller</a>{' '}
                    <span className="small">CEO / Senior Product Developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">NextGenTek, Inc. (USA)</div>

                <p className="card-text">
                    Since 1997 I have been heavily involved in SQL database
                    development. The last three years almost exclusively as a Win32/SQL
                    Server developer. Most of that development work was done in ODBC. While
                    ODBC is a powerful tool for application work, it is tedious, highly
                    error prone, and time consuming to use. During a brief programming
                    assignment with JAVA, I became familiar with JDBC. I was unhappy that a
                    similar kind of API set was not available to C++ programmers. Then by
                    great fortune I discovered SQLAPI and realized that it was just what I
                    had been looking for.
                </p>
                <details>
                    <summary>Read more...</summary>
                    <p className="card-text">
                        In June of 2001, my company began a project to develop
                        a major voice mail / unified messaging product that would have an
                        extensive SQL Server database component supporting both the Win32 GUI
                        and many NT service programs.&nbsp; SQLAPI was chosen as the
                        development tool for the database interface. After nine months of
                        programming using SQLAPI, I wonder how I ever got along without it. I
                        would never consider going back to ODBC and with SQLAPI's multi
                        platform support, I won't have to.
                    </p>
                    <p className="card-text">
                        I have been purchasing software development tools for
                        more than 25 years and I can say that the customer support from SQLAPI
                        has been excellent with quick, informative responses to my questions. I
                        have no reservations about strongly recommending SQLAPI to any
                        developer involved in database application work no matter what the
                        platform or the database product.
                    </p>
                    <p className="card-text">
                        After using SQLAPI for 15 years, I still believe it to be the best database
                        interface library available.
                        As CTO, my company's telephony services platform grew significantly in both
                        scope and complexity over that period.
                        Just prior to my recent retirement, this platform was serving more than 125,000
                        users.
                        It started day-one using SQLAPI for all of its SQL Server database access and it
                        still does.
                        It would not surprise me if that platform ran for another 10 years - the SQLAPI
                        license
                        was the most cost effective software investment I ever made. I simply cannot
                        imagine writing a complex database app without using SQLAPI.
                    </p>
                </details>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:jmorris@rtc-group.com">Jim Morris</a>{' '}
                    <span className="small">Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Retail Technologies Corporation (USA)</div>

                <p className="card-text">
                    I first discovered SQLAPI++ in early 2001 when
                    searching for an easy way to add ODBC and SQL capabilities to the
                    applications that Retail Technologies Corporation develops.&nbsp;At the
                    time, I has been learning exactly how painful and tedious it is to
                    write native ODBC code myself, and I knew that that had to be a better
                    way of doing things.&nbsp; An additional consideration at the time was
                    future portability of our current Windows applications to Linux in the
                    future. SQLAPI++ has served both of these needs very well.
                </p>
                <details>
                    <summary>Read more...</summary>
                    <p className="card-text">
                        SQLAPI++ provides a very well thought out C++ class
                        library for database access, offering not just ODBC, but native access
                        to just about all major databases.&nbsp; Additionally, SQLAPI++ is
                        available for both the Windows and Linux platforms - with source code
                        included.&nbsp; Using SQLAPI++, I have been able to take advantage of
                        SQL (and ODBC) with much less programming effort than might have
                        otherwise been required. This has helped reduce the time to market for
                        the products that I am developing.&nbsp; Furthermore, I have found the
                        technical support and customer service to be second to none.&nbsp;Any
                        time that I have run into issues or had questions, I have found the
                        staff at SQLAPI++ to be very responsive, always willing to go that
                        extra mile to help.
                    </p>
                </details>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:malcolmf@modcomp.co.uk">Malcolm Fisher</a>{' '}
                    <span className="small">Internet Development Consultant</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Modcomp Limited (UK)</div>
                <p className="card-text">
                    I have found SQLAPI++ to be a most useful development
                    tool. It has quickly enabled our own product to support a variety of
                    SQL Databases using a universal approach. Previously we had to use
                    various different mechanisms to access databases. The library has
                    proved itself on both Windows and UNIX platforms to be well written,
                    easy to use and stable.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:thomas@mediamatic.se">Thomas Borg</a>{' '}
                    <span className="small">Senior Software Developer and Architect</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Mediamatic Systems AB (Sweden)</div>
                <p className="card-text">
                    I have never found a better API to use to access the
                    databases my customers use and me. The API is very simple to use and it
                    doesn't matter if you're using MS SQL, Oracle or some other RDBMS. You
                    will get up and running really fast. This one is great. I highly
                    recommend this to anyone that needs to access an RDBMS.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:Delvotec@fastrun.at">Obermayr Johann</a>{' '}
                    <span className="small">Software development and network administrator</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">
                    F&amp;K Delvotec Bondtechnik GmbH&amp;CoKG (Austria)
                </div>
                <p className="card-text">It's a great tool.</p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:yingli@infoview.com.cn">Ying Li</a>{' '}
                    <span className="small">Advanced System Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">
                    Centell Kindom Technologies Corporation Shanghai Branch (China)
                </div>
                <p className="card-text">
                    Now we use SQLAPI on 3 platforms: Windows, SGI IRIX,
                    Sun Solaris.
                </p>
                <p className="card-text">
                    SQLAPI is great! It decreases DB programming work from N*M (N
                    platforms, M databases) to almost one. In our environment, we use
                    Informix and Oracle on IRIX, SQL Server and Oracle on Win2000, Sybase
                    on Solaris. Previously, I used ODBC++ on Windows platform, now I am
                    migrating to SQLAPI++.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:jliebman@asapofga.com">Jory Liebman</a>{' '}
                    <span className="small">Systems Programmer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">AS/AP of Georgia, Inc. (USA)</div>
                <p className="card-text">
                    Product is well-designed and easy to use. Support
                    response is excellent. Documentation good, but would be improved by a
                    more comprehensive set of sample code stuff. I WOULD recommend the
                    product to others.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:Jeff.Kish@mro.com">Jeff Kish</a>{' '}
                    <span className="small">Lead Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">MRO Software (USA)</div>
                <p className="card-text">
                    The SQLAPI support system is as good as any I have ever
                    encountered. Most questions have been resolved very quickly. The SQLAPI
                    product is responsible for minimizing the support load on our
                    development folks for our product that formerly used the Oracle Pro*c
                    precompiler. This enabled us to have less worry about which Oracle
                    client the customer is using, and allowed us to support Oracle and MS
                    SQL Server successfully with a single code base. I highly recommend it
                    for C++ development efforts.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:DCaillouet@littlerock.state.ar.us">Darryl Caillouet</a>{' '}
                    <span className="small">Programmer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">City of Little Rock (USA)</div>
                <p className="card-text">We purchased a site license for three reasons: </p>
                <ol className="card-text">
                    <li>
                        We have 4 different databases running on 2 different
                        platforms and we wanted a consistent way of accessing&nbsp; them using
                        C++.
                    </li>
                    <li>
                        While testing the demo version of the product, all
                        of our questions (both technical and non-technical)&nbsp; were promptly
                        answered.
                    </li>
                    <li>
                        Price. Lifetime support for unlimited developers for
                        the cost of two licenses. Not a bad deal.
                    </li>
                </ol>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:mkarami@scs-ref.com">Michael Karami</a>{' '}
                    <span className="small">C++ Developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Winteq (USA)</div>
                <p className="card-text">
                    SQLAPI is a fantastic lightweight API that enabled me
                    to easily connect to any SQL Server database within the intra-net and
                    perform all of my database transactions including queries, updates,
                    inserts, and executing stored procedures. The beauty of this software
                    is that the source code is provided to developer in case of need.
                    Lastly, the support for the product has been very efficient and has
                    resolved my issues promptly. I would recommend this product to every
                    C/C++ developer.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:djclarke@cm-solutions.co.nz">David Clarke</a>{' '}
                    <span className="small">Director - Technology</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Custom Made Solutions Ltd (New Zealand)</div>
                <p className="card-text">
                    After evaluation many SQL libraries available on the
                    Internet, I found SQLAPI++ to be the easiest to install and most
                    intuitive to understand.&nbsp;&nbsp;Competitive pricing and source code
                    availability were also important to us, and since implementation
                    SQLAPI++ has proved to be fast and&nbsp;stable in our WAN Radiology
                    Products.&nbsp; Excellent support has also been provided by the
                    SQLAPI++ team when needed.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:wehrlis@swissonline.ch">Walter Wehrli</a>{' '}
                    <span className="small">IT-Specialist/Software developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">we@r GmbH (Switzerland)</div>
                <p className="card-text">
                    SQLAPI++ does nothing which couldn't be done by
                    yourself, but it's already done and it's done well. It simply saves you
                    a lot of development and research time. It is also very reasonable
                    priced and the delivery of the complete source code is an insurance
                    against a "sudden disappearance" of the product.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:hdbeims@euregio.net">Prof. Dr. Hans Dieter Beims</a>{' '}
                    <span className="small">Manager</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Jacquemin &amp; Beims Consulting (Germany)</div>
                <p className="card-text">
                    My company develops client/server-applications with
                    standard DBMS (for example: Oracle, InterBase and Informix), especially
                    for the public administration. SQLAPI is for us the best library we
                    found to serve as a layer between our data-access-classes and the
                    DBMS-specific interface. Two other aspects should be emphasized:
                </p>
                <ul className="card-text">
                    <li>
                        it was very easy to switch from another library to
                        SQLAPI;
                    </li>
                    <li>
                        the advancements the SQLAPI developer made to remove
                        bugs and enhance the functionality.
                    </li>
                </ul>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:jerzy@jtomasik.com">Jerzy Tomasik</a>{' '}
                    <span className="small">Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">IDS Software Systems, USA (I used SQLAPI++
                    at my former employer, Texas Instruments)
                </div>
                <p className="card-text">
                    Few years ago I developed a program for loading fairly
                    complex data into Oracle. I used C++ and RogueWave's DBtools.h++ on
                    Solaris. A couple of years later I had to modify the program, and found
                    out I had to re-purchase DBtools.h++ because of lapsed maintenance
                    fees. After looking around, I found out that SQLAPI++ was a lot less
                    expensive, so I switched. But the best part was that the interface was
                    much cleaner. Using RogueWave, I had to "bounce" the data between C++
                    and RogueWave's classes, before I could pass it on to SQL. SQLAPI++
                    eliminated all this burden.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:info@medialabs.es">Santiago Revellado</a>{' '}
                    <span className="small">CEO</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">MediaLabs (Spain)</div>
                <p className="card-text">
                    With SQLAPI we really found an efficient&nbsp;and
                    simple way to manage SQL databases trough C++ applications.
                </p>
                <p className="card-text">
                    Technical support is fast, and very good.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:morlier@quetzal.fr">Jean-Claude Morlier</a>{' '}
                    <span className="small">Engineering manager</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">QUETZAL DATA (France)</div>
                <p className="card-text">
                    Good product and good ability to react
                    with Human Dimension.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:klacasse@securesvc.com">Ken Lacasse</a>{' '}
                    <span className="small">Director of IT</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Security Services of Connecticut (USA)</div>
                <p className="card-text">Easy to use, works well.</p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Fabrice Aeschbacher{' '}
                    <span className="small">Developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Siemens AG (Germany)</div>
                <p className="card-text">
                    Very easy to use, very fast, and more reliable than any
                    ODBC or ADO. Further, the technical support is very quick and good. We
                    are very happy with SQLAPI.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Lee Alcorn{' '}
                    <span className="small">Lead Middleware Developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Link2Gov (USA)</div>
                <p className="card-text">
                    I was under an extremely tight development schedule to
                    deliver an application to call and retrieve results from Oracle
                    packages. Without enough time to write my own handlers, I knew a third
                    party library would be necessary. I researched a number of different
                    libraries and SQLAPI++ had everything I needed at, what I realize now
                    after using it, a phenomenal price. Integration into our project was
                    simple, support was fantastic, and performance was unbeatable. SQLAPI++
                    delivered! Thank You!
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Jan B. Ahlbeck{' '}
                    <span className="small">Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">FKI Logistex, Dator-Crisplant A/S (Denmark)</div>
                <p className="card-text">
                    SQLAPI++ is easy to use, well documented. GREAT
                    support, if you have a question, which is seldom, you have an
                    answer&nbsp;within minutes. The price is so low that it is almost
                    free.&nbsp;We have used it with MSSQL&nbsp;and Oracle without any
                    problems. GREAT PRODUCT !!!
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    David Bertrand{' '}
                    <span className="small">Software Developer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Post Impressions Systems Inc. (a Snell
                    &amp; Wilcox company)(Canada)
                </div>
                <p className="card-text">
                    SQLAPI has given us the performance usually only found
                    when using databases native APIs without compromising portability.
                    Customer service is first class and online documentation is extensive.
                    I would definitely recommend it!
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Ronald B. Knecht{' '}
                    <span className="small">Director Databases and Interfaces</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">InterTransact S.A. (Luxembourg)</div>
                <p className="card-text">
                    We find SQLAPI++ easy to use and well documented.
                    Really worth its price.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Silvio Iaccarino{' '}
                    <span className="small">Developer / PAISY CLIENT SERVER R&amp;D</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">ADP Employer Services (Germany)</div>
                <p className="card-text">
                    SQLAPI++ is straightforward and efficient - a
                    recommendation for cross platform client server development.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Serguei Ovtchinnikov{' '}
                    <span className="small">Programmer(X-Gen project)</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">
                    <a href="http://www.csksoftware.com/">CSK corporation</a> (Germany)
                </div>
                <p className="card-text">Very useful product, support is also very fast.</p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Armin Tueting{' '}
                    <span className="small">Owner, Project Manager and Developer, PMI certified (PMP)</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted"> Dipl.-Ing. (FH)&nbsp;Armin Tueting (Germany)</div>
                <p className="card-text">
                    I bought the SQLAPI++ a few days before. For now, It
                    worked greatly in every WIN32 environment
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Alexander Horak
                    <span className="small ml">Development Project Manager</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">combit GmbH (Germany)</div>
                <p className="card-text">
                    We use SQLAPI++ to access MSSQL and PostgreSQL
                    databases under WIN32 systems. SQLAPI has a good support and an
                    unbeatable price. The world needs more products like this.
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    <a href="mailto:peter.bittner@gtconsulting.at">Peter Bittner</a>{' '}
                    <span className="small">IT-Consultant</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">gT-Consulting GmbH (Austria)</div>
                <p className="card-text">
                    I found SQLAPI++ searching the web for a solution to
                    connect to MySQL and, optionally, to other databases. SQLAPI++ is
                    really easy to use, quickly to get started with and worked right away.
                    I was astonished!
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Polly Tang{' '}
                    <span className="small">Senior Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">Motorola Inc.(USA)</div>
                <p className="card-text">
                    SQLAPI++ is clean, easy to use and has well thought out
                    class library for database access.
                    We tested it out on our applications with Oracle and Sybase on Windows
                    and Linux platform,
                    and it runs really well. We are able to deploy our application to any
                    of our customers without deployment license. Also, SQLAPI++ has
                    knowledgeable technical support and the response time
                    is excellent. I highly recommend this software. Great work!
                </p>
            </div>
        </Article>

        <Article>
            <div className="card-body">
                <h5 className="card-title">
                    Jesus Gonzalez Marti{' '}
                    <span className="small">Senior Software Engineer</span>
                </h5>
                <div className="card-subtitle mb-2 text-muted">
                    <a href="http://www.indisys.es/">
                        Intelligent
                        Dialogue Systems S.L. [Indisys]
                    </a> (Spain)
                </div>
                <p className="card-text">
                    We were looking for an
                    API to DB access with the following requirements:<br/>
                    - Native C++ language.<br/>
                    - Win32 and Linux support.<br/>
                    - Several DB support.<br/>
                    - Easy to use.<br/>
                    And we found it! We dediced to acquire SQLAPI++ after deep test with
                    demo version.
                    It's stable, fast and it's really easy to learn how to use it.
                </p>
            </div>
        </Article>
    </Layout>
);

export default Page;
